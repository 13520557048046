export const landingPageTitle = "MIU sculptures";

export const landingPageActionButtonMessage =
  "Olá!%20Gostaria%20de%20receber%20mais%20informações%20sobre%20os%20serviços%20oferecidos.";
export const landingPageActionButtonNumber = "5548996682337";
export const cellphone = "48996682337";

export const socialMedias = [
  {
    name: "phone",
    link: `tel:${cellphone}`,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/lmcomunica/",
  },
  {
    name: "whatsapp",
    link: `https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`,
  },
  {
    name: "email",
    link: "mailto:lmcomunicacao.empresa@gmail.com",
  },
];

import { Box, Container, Typography } from "@mui/material";
import image1 from "../../content/images/image1.png";
import image2 from "../../content/images/image2.png";
import image3 from "../../content/images/image3.png";
import image4 from "../../content/images/image4.png";
import image5 from "../../content/images/image5.png";
import image6 from "../../content/images/image6.png";
import image7 from "../../content/images/image7.png";
import image8 from "../../content/images/image8.png";
import image9 from "../../content/images/image9.png";
import image10 from "../../content/images/image10.png";
import image11 from "../../content/images/image11.png";

export default function PhotographySection() {
  return (
    <Container sx={{ paddingY: 10, width: "100%" }}>
      <Box sx={{ display: { md: "flex" }, mb: 5, letterSpacing: 5 }}>
        <Typography variant="h4">Fotogafia emoldurada</Typography>
        <Typography sx={{ ml: { md: 2 }, fontStyle: "italic" }} variant="h5">
          Framed photography
        </Typography>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          mb: 10,
        }}
      >
        <img alt="metamorphic-1" src={image1} width="100%" />
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 1,
          }}
        >
          <Typography fontWeight={600}>Santo Graal</Typography>
          <Typography fontStyle="italic">Holy Grail</Typography>
        </Box>

        <Typography>Fotomontagem - Praia Mole / Florianópolis</Typography>
        <Typography>
          Impressão pigmento mineral sobre Hahnemühle Art Canvas
        </Typography>
        <Typography>Smooth 370gsm Poly-Cotton Natural White</Typography>
        <Typography mb={2}>240 x 90 cm</Typography>
        <Typography>R$ 15.000</Typography>
        <Typography>U$ 2.700</Typography>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 10,
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image2} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Bailarinas</Typography>
            <Typography fontStyle="italic">Ballerinas</Typography>
          </Box>

          <Typography>Fotografia- Playa Buchupureo / Chile</Typography>
          <Typography>Impressão pigmento mineral sobre papel</Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>120 x 90 cm</Typography>
          <Typography>R$ 7.000</Typography>
          <Typography>U$ 1.300</Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image7} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Flow</Typography>
          </Box>
          <Typography>Fotografia - Praia de Itapororoca / Bahia</Typography>
          <Typography>Impressão pigmento mineral sobre papel </Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>135 x 90 cm</Typography>
          <Typography>R$ 8.000</Typography>
          <Typography>U$ 1.500</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 10,
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image3} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Yin Yang</Typography>
          </Box>

          <Typography>Fotografia - Praia de Itapororoca / Bahia</Typography>
          <Typography>Impressão pigmento mineral sobre papel</Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>120 x 90 cm</Typography>
          <Typography>R$ 7.000</Typography>
          <Typography>U$ 1.300</Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image8} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Despedaços</Typography>
            <Typography fontStyle="italic">Shattered</Typography>
          </Box>
          <Typography>Fotografia - Praia de Itapororoca / Bahia</Typography>
          <Typography>Impressão pigmento mineral sobre papel </Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>135 x 90 cm</Typography>
          <Typography>R$ 8.000</Typography>
          <Typography>U$ 1.500</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 10,
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image4} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Praia Branca</Typography>
            <Typography fontStyle="italic">White Beach</Typography>
          </Box>

          <Typography>
            Fotografia - Praia do Campeche / Florianópolis
          </Typography>
          <Typography>Impressão pigmento mineral sobre papel</Typography>
          <Typography>Hahnemühle Hemp 290gsm </Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>120 x 90 cm</Typography>
          <Typography>R$ 7.000</Typography>
          <Typography>U$ 1.300</Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image9} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Pérola Negra</Typography>
            <Typography fontStyle="italic">Black Pear</Typography>
          </Box>
          <Typography>Fotografia - Playa Buchupureo / Chile</Typography>
          <Typography>Impressão pigmento mineral sobre papel </Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>135 x 90 cm</Typography>
          <Typography>R$ 8.000</Typography>
          <Typography>U$ 1.500</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 10,
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image5} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Lampião</Typography>
            <Typography fontStyle="italic">Lamp</Typography>
          </Box>

          <Typography>
            Fotografia - Praia do Campeche / Florianópolis
          </Typography>
          <Typography>Impressão pigmento mineral sobre papel</Typography>
          <Typography>Hahnemühle Hemp 290gsm </Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>120 x 90 cm</Typography>
          <Typography>R$ 7.000</Typography>
          <Typography>U$ 1.300</Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image10} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Córdula</Typography>
            <Typography fontStyle="italic">Little Heart</Typography>
          </Box>
          <Typography>Fotografia - Praia da Ursa / Portugal</Typography>
          <Typography>Impressão pigmento mineral sobre papel </Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>135 x 90 cm</Typography>
          <Typography>R$ 8.000</Typography>
          <Typography>U$ 1.500</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 4,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 10,
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image6} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Dança da Solidão</Typography>
            <Typography fontStyle="italic">Dance of loneliness</Typography>
          </Box>

          <Typography>
            Fotografia - Dunas da Joaquina / Florianópolis
          </Typography>
          <Typography>Impressão pigmento mineral sobre papel</Typography>
          <Typography>Hahnemühle Hemp 290gsm </Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>120 x 90 cm</Typography>
          <Typography>R$ 7.000</Typography>
          <Typography>U$ 1.300</Typography>
        </Box>

        <Box
          sx={{
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img alt="metamorphic-1" src={image11} width="100%" />
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 1,
            }}
          >
            <Typography fontWeight={600}>Amonita</Typography>
            <Typography fontStyle="italic">Ammonite</Typography>
          </Box>
          <Typography>
            Fotografia - Cacimaba do Padre / Fernando de Noronha
          </Typography>
          <Typography>Impressão pigmento mineral sobre papel </Typography>
          <Typography>Hahnemühle Hemp 290gsm</Typography>
          <Typography>60% hemp fibre - 40% cotton</Typography>
          <Typography mb={2}>135 x 90 cm</Typography>
          <Typography>R$ 8.000</Typography>
          <Typography>U$ 1.500</Typography>
        </Box>
      </Box>
    </Container>
  );
}

import React from "react";
import "./App.css";
import LandingSection from "./components/molecules/LandingSection";
import withRoot from "./withRoot";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import SiteLayout from "./components/layout/SiteLayout";
import BiographySection from "./components/molecules/Biography";
import DeclarationSection from "./components/molecules/Declaration";
import ClaySection from "./components/molecules/Clay";
import PhotographySection from "./components/molecules/Photography";
import ContactSection from "./components/molecules/Contact";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <SiteLayout>
          <Outlet />
        </SiteLayout>
      }
    >
      <Route path="/" element={<LandingSection />} />
      <Route path="/bio" element={<BiographySection />} />
      <Route path="/declaration" element={<DeclarationSection />} />
      <Route path="/clay" element={<ClaySection />} />
      <Route path="/photopraphy" element={<PhotographySection />} />
      <Route path="/contact" element={<ContactSection />} />
    </Route>
  )
);

function App() {
  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default withRoot(App);

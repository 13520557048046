import { Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

export default function SiteLayout({ children }: Props) {
  const { pathname } = useLocation();
  return (
    <Box maxWidth="100vw">
      <Tabs
        sx={{
          position: "absolute",
          right: { xs: 0, md: 20 },
          zIndex: 99,
          color: "white",
          overflowX: "auto",
          width: { xs: "100vw", md: "auto" },
        }}
        value={pathname}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Home"
          value="/"
          to="/"
          component={Link}
        />
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Biografia"
          value="/bio"
          to="/bio"
          component={Link}
        />
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Declaração"
          value="/declaration"
          to="/declaration"
          component={Link}
        />
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Cerâmicas"
          value="/clay"
          to="/clay"
          component={Link}
        />
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Fotografias"
          value="/photopraphy"
          to="/photopraphy"
          component={Link}
        />
        <Tab
          sx={{
            color:
              pathname === "/" || pathname === "/contact"
                ? "white !important"
                : "primary.main",
            textTransform: "none",
            letterSpacing: { md: 2 },
            marginBottom: "2px solid white",
          }}
          label="Contato"
          value="/contact"
          to="/contact"
          component={Link}
        />
      </Tabs>
      {children}
    </Box>
  );
}

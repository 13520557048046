import { Box, Container, Typography } from "@mui/material";
import metamorphics1 from "../../content/images/metamorphics-1.png";
import metamorphics2 from "../../content/images/metamorphics-2.png";
import frame from "../../content/images/frame.png";
import installation from "../../content/images/installation.png";
import copper from "../../content/images/copper.png";
import dancer1 from "../../content/images/dancer1.png";
import dancer2 from "../../content/images/dancer2.png";
import dancer3 from "../../content/images/dancer3.png";
import dancer4 from "../../content/images/dancer4.png";
import dancer5 from "../../content/images/dancer5.png";
import beings1 from "../../content/images/beings1.png";
import beings2 from "../../content/images/beings2.png";
import beings3 from "../../content/images/beings3.png";
import beings4 from "../../content/images/beings4.png";
import beings5 from "../../content/images/beings5.png";
import beings6 from "../../content/images/beings6.png";
import beings7 from "../../content/images/beings7.png";
import beings8 from "../../content/images/beings8.png";
import beings9 from "../../content/images/beings9.png";
import beings10 from "../../content/images/beings10.png";
import beings11 from "../../content/images/beings11.png";
import beings12 from "../../content/images/beings12.png";
import beings13 from "../../content/images/beings13.png";
import beings14 from "../../content/images/beings14.png";
import beings15 from "../../content/images/beings15.png";
import beings16 from "../../content/images/beings16.png";
import mandala1 from "../../content/images/mandala1.png";
import mandala2 from "../../content/images/mandala2.png";
import mandala3 from "../../content/images/mandala3.png";
import mandala4 from "../../content/images/mandala4.png";
import mandala5 from "../../content/images/mandala5.png";

export default function ClaySection() {
  return (
    <Container sx={{ paddingY: 10, width: "100%" }}>
      <Typography variant="h4" letterSpacing={5} mb={5}>
        Esculturas de Cerâmicas - Jardim de Vênus
      </Typography>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 2,
          mb: 5,
          letterSpacing: 5,
        }}
      >
        <Typography variant="h5">Metafóricas</Typography>
        <Typography variant="h5" fontStyle="italic">
          Metamorphic
        </Typography>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 6,
          mb: 2,
        }}
      >
        <img
          alt="metamorphic-1"
          src={metamorphics1}
          width={window.screen.width < 720 ? "100%" : "48%"}
        />
        <img
          alt="metamorphic-1"
          src={metamorphics2}
          width={window.screen.width < 720 ? "100%" : "48%"}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", mb: 5 }}>
        <Box sx={{ gap: 2, letterSpacing: 0.5, textAlign: "end" }}>
          <Typography>Argila grés</Typography>
          <Typography>Queima a lenha </Typography>
          <Typography mb={2}>Aprox. 45 x 30 cm</Typography>
          <Typography>R$ 1.500 cada peça</Typography>
          <Typography>U$ 275 each piece</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 6,
          mb: 5,
          letterSpacing: 5,
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "48%" }, mb: { xs: 5, md: 0 } }}>
          <Typography variant="h5" mb={5}>
            Mural
          </Typography>

          <img alt="metamorphic-1" src={frame} width="100%" />

          <Box sx={{ gap: 2, letterSpacing: 0.5, mt: 2 }}>
            <Typography fontWeight={600}>Jibóias</Typography>
            <Typography>
              Porcelanas com pintura de óxido de cobre e óxido de ferro
            </Typography>
            <Typography>Queima em forno elétrico </Typography>
            <Typography>27 peças</Typography>
            <Typography mb={2}>Aprox. 4 x 3 m</Typography>
            <Typography>R$ 60.000</Typography>
            <Typography>U$ 10.900</Typography>
          </Box>
        </Box>

        <Box sx={{ width: { xs: "100%", md: "48%" } }}>
          <Typography variant="h5" mb={5}>
            Instalação
          </Typography>
          <img alt="metamorphic-1" src={installation} width="100%" />

          <Box sx={{ gap: 2, letterSpacing: 0.5, mt: 2 }}>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Voadoras da paz </Typography>
              <Typography fontStyle="italic">Flying beings of peace</Typography>
            </Box>
            <Typography>Porcelana</Typography>
            <Typography>Esmalte Matte</Typography>
            <Typography>Queima em forno elétrico</Typography>
            <Typography>30 peças</Typography>
            <Typography mb={2}>Aprox. 3,6 x 2 x 3,20 m</Typography>
            <Typography>R$ 50.000</Typography>
            <Typography>U$ 9.100</Typography>
          </Box>
        </Box>
      </Box>

      <Box mb={5}>
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            mb: 5,
            letterSpacing: 5,
          }}
        >
          <Typography variant="h5">Dançarinas</Typography>
          <Typography variant="h5" fontStyle="italic">
            Dancers
          </Typography>
        </Box>

        <Box
          sx={{ gap: 2, letterSpacing: 0.5, display: { md: "flex" }, mb: 5 }}
        >
          <img
            alt="metamorphic-1"
            src={dancer1}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />

          <Box sx={{ alignContent: "end" }}>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
                justifyContent: "end",
              }}
            >
              <Typography fontWeight={600}>Romeu e Julieta</Typography>
              <Typography fontStyle="italic">Romeo and Juliet</Typography>
            </Box>

            <Typography>Argila preta e porcelana</Typography>
            <Typography>Queima em forno elétrico</Typography>
            <Typography mb={2}>70 x 30 cm e 45 x 25 cm </Typography>
            <Typography>R$ 15.000</Typography>
            <Typography>U$ 2.750</Typography>
          </Box>
        </Box>
        <Box
          sx={{ gap: 2, letterSpacing: 0.5, display: { md: "flex" }, mb: 5 }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
            }}
          >
            <img
              alt="metamorphic-1"
              src={dancer2}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Dança Coral</Typography>
                <Typography fontStyle="italic">Coral Dance</Typography>
              </Box>

              <Typography>Porcelana</Typography>
              <Typography>Queima em forno Anagama</Typography>
              <Typography mb={2}>27 x 20 cm e 26x 10 cm</Typography>
              <Typography>R$ 7.000</Typography>
              <Typography>U$ 1.270</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Box sx={{ textAlign: "end", alignContent: "end" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,

                  justifyContent: "end",
                }}
              >
                <Typography fontWeight={600}>Goldinha</Typography>
                <Typography fontStyle="italic">Little Gold</Typography>
              </Box>

              <Typography>Argila marfim com vermiculita</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>100 x 46 cm</Typography>
              <Typography>R$ 15.000</Typography>
              <Typography>U$ 2.750</Typography>
            </Box>

            <img
              alt="metamorphic-1"
              src={dancer3}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
          </Box>
        </Box>

        <Box
          sx={{ gap: 2, letterSpacing: 0.5, display: { md: "flex" }, mb: 5 }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
            }}
          >
            <img
              alt="metamorphic-1"
              src={dancer4}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Dança em Bali</Typography>
                <Typography fontStyle="italic">Dance in Bali</Typography>
              </Box>

              <Typography>Argila Grés</Typography>
              <Typography>Queima de soda em forno a gás</Typography>
              <Typography mb={2}>30 x 20 cm e 28 x 17 cm</Typography>
              <Typography>R$ 9.000</Typography>
              <Typography>U$ 1.650</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Box sx={{ textAlign: "end", alignContent: "end" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                  justifyContent: "end",
                }}
              >
                <Typography fontWeight={600}>Minueto</Typography>
                <Typography fontStyle="italic">Minuet</Typography>
              </Box>

              <Typography>Argila Tabaco - Queima a lenha</Typography>
              <Typography>Porcelana - Queima em forno elétrico</Typography>
              <Typography mb={2}>40 x 20 cm e 30 x 18 cm</Typography>
              <Typography>R$ 9.000</Typography>
              <Typography>U$ 1.650</Typography>
            </Box>

            <img
              alt="metamorphic-1"
              src={dancer5}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 6,
          mb: 5,
          letterSpacing: 5,
        }}
      >
        <Box>
          <Typography variant="h5" mb={5}>
            Bronze
          </Typography>

          <Box sx={{ display: { xs: "block", md: "flex" }, gap: 2 }}>
            <img
              alt="metamorphic-1"
              src={copper}
              width={window.screen.width < 720 ? "100%" : "60%"}
            />

            <Box sx={{ letterSpacing: 0.5, alignContent: "end" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Dança nas profundezas</Typography>
                <Typography fontStyle="italic">Dance in the depths</Typography>
              </Box>
              <Typography>Bronze 85%</Typography>
              <Typography>
                Base granito São Gabriel escovado de 165 x 75 x 20 cm
              </Typography>
              <Typography mb={2}>
                Esculturas 160 x 100 cm e 123 x 70 cm
              </Typography>
              <Typography>R$ 350.000</Typography>
              <Typography>U$ 63.650</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mb={5}>
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            mb: 5,
            letterSpacing: 5,
          }}
        >
          <Typography variant="h5">Seres</Typography>
          <Typography variant="h5" fontStyle="italic">
            Beings
          </Typography>
        </Box>
        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings1}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Heart Shell I</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>110 x 70 x 40 cm</Typography>
              <Typography>R$ 25.000</Typography>
              <Typography>U$ 4.550</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings2}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Volupta</Typography>
                <Typography fontStyle="italic">Voluptuous</Typography>
              </Box>
              <Typography>Argila Grés</Typography>
              <Typography>
                Pintura com óxido de cobre e óxido de ferro
              </Typography>{" "}
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>80 x 80 x 35 cm</Typography>
              <Typography>R$ 25.000</Typography>
              <Typography>U$ 4.550</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings3}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Escorpião</Typography>
                <Typography fontStyle="italic">Scorpio</Typography>
              </Box>

              <Typography>Argila preta com vermiculita</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>56 x 31 x 17 cm</Typography>
              <Typography>R$ 11.000</Typography>
              <Typography>U$ 2.000</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings4}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Rastejante</Typography>
                <Typography fontStyle="italic">Crawling</Typography>
              </Box>
              <Typography>Argila marfim com vermiculita</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>108 x 67 x 33 cm</Typography>
              <Typography>R$ 18.000</Typography>
              <Typography>U$ 3.270</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings5}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Heart Shell II</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>100 x 60 x 46 cm</Typography>
              <Typography>R$ 25.000</Typography>
              <Typography>U$ 4.550</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings6}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Solitude III</Typography>
              </Box>
              <Typography>Argila preta com vermiculita</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>54 x 54 x 65 cm</Typography>
              <Typography>R$ 28.000</Typography>
              <Typography>U$ 5.100</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings7}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Sedução</Typography>
                <Typography fontStyle="italic">Seduction</Typography>
              </Box>

              <Typography>Argila terracota</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>31 x 50 x 47 cm</Typography>
              <Typography>R$ 13.000</Typography>
              <Typography>U$ 2.400</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings8}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Triton</Typography>
              </Box>
              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>110 x 60 cm</Typography>
              <Typography>R$ 30.000</Typography>
              <Typography>U$ 5.500</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings9}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Fonte</Typography>
                <Typography fontStyle="italic">Foutain</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>62 x 56 x 29 cm</Typography>
              <Typography>R$ 13.000</Typography>
              <Typography>U$ 2.400</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings10}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Light Solitude</Typography>
              </Box>
              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>50 x 50 x 48 cm</Typography>
              <Typography>R$ 15.000</Typography>
              <Typography>U$ 2.800</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings11}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Pteridium</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>
                Pintura com óxido de cobre e óxido de ferro
              </Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography>Duas peças com encaixe</Typography>
              <Typography mb={2}>25 x 35 x 55 cm e 50 x 50 x 70 cm</Typography>
              <Typography>R$ 35.000</Typography>
              <Typography>U$ 6.400</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings12}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Even Flow</Typography>
              </Box>
              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>80 x 50 x 50 cm</Typography>
              <Typography>R$ 25.000</Typography>
              <Typography>U$ 4.600</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings13}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Magic Flow</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>80 x 60 x 45 cm</Typography>
              <Typography>R$ 21.000</Typography>
              <Typography>U$ 3.900</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings14}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Uma Só</Typography>
                <Typography fontStyle="italic">One and Only</Typography>
              </Box>
              <Typography>Argila grés com vermiculita</Typography>
              <Typography>Queima em forno a lenha</Typography>
              <Typography mb={2}>37 x 34 x 25 cm</Typography>
              <Typography>R$ 10.000</Typography>
              <Typography>U$ 1.900</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            gap: 2,
            letterSpacing: 0.5,
            display: { md: "flex" },
            mb: 5,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings15}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Arraiou III</Typography>
              </Box>

              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>53 x 43 x 40 cm</Typography>
              <Typography>R$ 15.000</Typography>
              <Typography>U$ 2.800</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              gap: 2,
              alignItems: "end",
              justifyContent: "end",
              width: { md: "50%" },
            }}
          >
            <img
              alt="metamorphic-1"
              src={beings16}
              width={window.screen.width < 720 ? "100%" : "48%"}
            />
            <Box width={{ md: "240px" }}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 1,
                }}
              >
                <Typography fontWeight={600}>Romance</Typography>
              </Box>
              <Typography>Argila grés</Typography>
              <Typography>Pintura com óxido de cobre</Typography>
              <Typography>Queima em forno a lenha </Typography>
              <Typography mb={2}>52 x 52 x 45 cm</Typography>
              <Typography>R$ 15.000</Typography>
              <Typography>U$ 2.800</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 2,
          mb: 5,
          letterSpacing: 5,
        }}
      >
        <Typography variant="h5">Mandalas do Mar</Typography>
        <Typography variant="h5" fontStyle="italic">
          Sea Mandalas
        </Typography>
      </Box>

      <Box
        sx={{
          gap: 2,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img
            alt="metamorphic-1"
            src={mandala1}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />
          <Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Bali Clam</Typography>
            </Box>

            <Typography>Argila grés</Typography>
            <Typography>Pintura com óxido de cobre e óxido de ferro</Typography>
            <Typography>Queima em forno a lenha </Typography>
            <Typography mb={2}>86 x 64 x 9 cm</Typography>
            <Typography>R$ 16.000</Typography>
            <Typography>U$ 2.950</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img
            alt="metamorphic-1"
            src={mandala2}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />
          <Box width={{ md: "240px" }}>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Corculum</Typography>
            </Box>
            <Typography>Argila grés</Typography>
            <Typography>Pintura com óxido de ferro</Typography>
            <Typography>Queima em forno a lenha</Typography>
            <Typography mb={2}>65 x 65 x 8 cm</Typography>
            <Typography>R$ 16.000</Typography>
            <Typography>U$ 2.950</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 2,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img
            alt="metamorphic-1"
            src={mandala3}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />
          <Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Nepali Star</Typography>
            </Box>

            <Typography>Argila grés</Typography>
            <Typography>Pintura com óxido de cobre e óxido de ferro</Typography>
            <Typography>Queima em forno a lenha </Typography>
            <Typography mb={2}>90 x 80 x 13 cm</Typography>
            <Typography>R$ 16.000</Typography>
            <Typography>U$ 2.950</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            alignItems: "end",
            justifyContent: "end",
            width: { md: "50%" },
          }}
        >
          <img
            alt="metamorphic-1"
            src={mandala4}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />
          <Box width={{ md: "240px" }}>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Yin Yang</Typography>
            </Box>
            <Typography>Argila grés</Typography>
            <Typography>Pintura com óxido de cobre e óxido de ferro</Typography>
            <Typography>Queima em forno a lenha</Typography>
            <Typography mb={2}>50 x 60 x 12 cm</Typography>
            <Typography>R$ 16.000</Typography>
            <Typography>U$ 2.950</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          gap: 2,
          letterSpacing: 0.5,
          display: { md: "flex" },
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: 2,
            alignItems: "end",
            width: { md: "50%" },
          }}
        >
          <img
            alt="metamorphic-1"
            src={mandala5}
            width={window.screen.width < 720 ? "100%" : "48%"}
          />
          <Box>
            <Box
              sx={{
                display: { xs: "block", md: "flex" },
                gap: 1,
              }}
            >
              <Typography fontWeight={600}>Leque</Typography>
            </Box>

            <Typography>Argila grés</Typography>
            <Typography>Pintura com óxido de cobre</Typography>
            <Typography>Queima em forno a lenha </Typography>
            <Typography mb={2}>80 x 80 x 13 cm</Typography>
            <Typography>R$ 16.000</Typography>
            <Typography>U$ 2.950</Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

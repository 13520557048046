import { Link, Typography } from "@mui/material";
import LandingSectionLayout from "./LandingSectionLayout";
import { landingPageTitle } from "../../content";
import contactImage from "../../content/images/contact-image.jpeg";

export const landingPageActionButtonNumber = "5548991824224";

const link = `https://wa.me/${landingPageActionButtonNumber}`;

export default function ContactSection() {
  return (
    <LandingSectionLayout
      sxBackground={{
        backgroundImage: `url(${contactImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={contactImage}
        alt="increase priority"
      />
      <Typography
        variant="h3"
        color="white"
        align="center"
        letterSpacing={10}
        mb={2}
      >
        {landingPageTitle}
      </Typography>
      <Link
        color="#FFFFFF"
        letterSpacing={10}
        onClick={() => window.open(link, "_blank")}
        sx={{ cursor: "pointer", textAlign: "center" }}
      >
        Clique aqui e entre em contato
      </Link>
    </LandingSectionLayout>
  );
}

import { Box, Container, Typography } from "@mui/material";

export default function DeclarationSection() {
  return (
    <Container sx={{ paddingY: 10 }}>
      <Box sx={{ display: { md: "flex" }, mb: 5, letterSpacing: 5 }}>
        <Typography variant="h4">Declaração</Typography>
        <Typography
          sx={{
            ml: { md: 2 },
            fontStyle: "italic",
            display: { xs: "none", md: "block" },
          }}
          variant="h5"
        >
          Artist statement
        </Typography>
      </Box>
      <Box sx={{ display: { md: "flex" } }}>
        <Box sx={{ flex: 1, letterSpacing: 1 }}>
          <Typography>
            Entre o peso e a leveza, a forma é beleza visceral.
          </Typography>
          <Typography>Captura o olhar do espectador,</Typography>
          <Typography>Deleite de sua própria essência interna.</Typography>
          <Typography marginBottom={2}>
            Há uma inocência em contraste com a sexualidade.
          </Typography>
          <Typography>Mergulha fundo.</Typography>
          <Typography>Na intimidade dos lugares mais escuros,</Typography>
          <Typography>Nos mistérios das constantes mudanças.</Typography>
          <Typography marginBottom={2}>
            Impermanência do ser no mundo.
          </Typography>
          <Typography marginBottom={2}>
            No encontro da luz, a contemplação.
          </Typography>
          <Typography>O intenso processo</Typography>
          <Typography>De esculpir areia e argila.</Typography>
          <Typography>O trabalho duro, a mente vazia,</Typography>
          <Typography marginBottom={2}>Ressurge o que há de vida.</Typography>
          <Typography>Tocar, sentir, experienciar,</Typography>
          <Typography>Virar de dentro para fora.</Typography>
          <Typography>Movimento, dança e mãos</Typography>
          <Typography marginBottom={2}>
            Faz o corpo fluir através do gesto.
          </Typography>
          <Typography>Conectando com o coração</Typography>
          <Typography>Encontra um refúgio das grandes tempestades,</Typography>
          <Typography>Onde a solitude e a paz reinam,</Typography>
          <Typography>Lugar oculto no passado</Typography>
          <Typography marginBottom={2}>
            Mais distante do que os ancestrais conseguem recordar.
          </Typography>
          <Typography>Imergindo em seu próprio mundo,</Typography>
          <Typography>Onde voa com os pássaros,</Typography>
          <Typography>Flutua e risca alto no céu.</Typography>
          <Typography>
            Às vezes como água, derretendo a terra sob seus pés,
          </Typography>
          <Typography marginBottom={2}>
            Às vezes como sol, ardendo sua pele exposta.
          </Typography>
          <Typography>Caminho de poesia, de volta ao útero materno.</Typography>
          <Typography>Completude do ser,</Typography>
          <Typography>As estrelas falam,</Typography>
          <Typography>A lua acalma,</Typography>
          <Typography marginBottom={2}>Alma.</Typography>
          <Typography>Eu sou Natureza...</Typography>
          <Typography>Onde me encontro,</Typography>
          <Typography>Vínculo que pulsa emoção</Typography>
          <Typography>Forte o bastante para fazer o amor renascer.</Typography>
        </Box>

        <Box sx={{ letterSpacing: 1 }}>
          <Typography
            sx={{
              ml: { md: 2 },
              fontStyle: "italic",
              display: { md: "none" },
              my: 5,
            }}
            variant="h5"
          >
            Artist statement
          </Typography>
          <Typography>
            Between weight and lightness, the form is visceral beauty.
          </Typography>
          <Typography>It captures the viewer's gaze,</Typography>
          <Typography>A delight of its own internal essence.</Typography>
          <Typography marginBottom={2}>
            There is innocence in contrast to sexuality.
          </Typography>
          <Typography>It dives deep.</Typography>
          <Typography>Into the intimacy of the darkest places,</Typography>
          <Typography>Into the mysteries of constant changes.</Typography>
          <Typography marginBottom={2}>
            Impermanence of self in the world.
          </Typography>
          <Typography marginBottom={2}>
            In the encounter with light, contemplation.
          </Typography>
          <Typography>The intense process</Typography>
          <Typography>Of sculpting sand and clay.</Typography>
          <Typography>Hard work, an empty mind,</Typography>
          <Typography marginBottom={2}>
            Reviving the essence of life.
          </Typography>
          <Typography>Touch, feel, experience,</Typography>
          <Typography>Turning inside out.</Typography>
          <Typography>Movement, dance, and hands</Typography>
          <Typography marginBottom={2}>
            Make the body flow through the gesture.
          </Typography>
          <Typography>Connecting with the heart</Typography>
          <Typography>Finding a refuge from great storms,</Typography>
          <Typography>Where solitude and peace reign,</Typography>
          <Typography>A hidden place in the past</Typography>
          <Typography marginBottom={2}>
            Further back than the ancestors can recall.
          </Typography>
          <Typography>Immersing in its own world,</Typography>
          <Typography>Where it flies with the birds,</Typography>
          <Typography>Floats and traces high in the sky.</Typography>
          <Typography>
            Sometimes like water, melting the earth beneath its feet,
          </Typography>
          <Typography marginBottom={2}>
            Sometimes like the sun, burning its exposed skin.
          </Typography>
          <Typography>A path of poetry, back to the maternal womb.</Typography>
          <Typography>Completeness of being,</Typography>
          <Typography>Declaration</Typography>
          <Typography>The stars speak,</Typography>
          <Typography>The moon soothes,</Typography>
          <Typography marginBottom={2}>Soul.</Typography>
          <Typography>I am Nature...</Typography>
          <Typography>Where I find myself,</Typography>
          <Typography>A bond pulsing with emotion</Typography>
          <Typography>Strong enough to make love reborn.</Typography>
        </Box>
      </Box>
    </Container>
  );
}

import { Box, Container, Typography } from "@mui/material";

export default function BiographySection() {
  return (
    <Container sx={{ paddingY: 10 }}>
      <Box sx={{ display: { md: "flex" }, mb: 5, letterSpacing: 5 }}>
        <Typography variant="h4">Biografia</Typography>
        <Typography
          sx={{
            ml: { md: 2 },
            fontStyle: "italic",
            display: { xs: "none", md: "block" },
          }}
          variant="h5"
        >
          Biography
        </Typography>
      </Box>
      <Box sx={{ display: { md: "flex" } }}>
        <Box sx={{ flex: 1, letterSpacing: 1, marginRight: 10 }}>
          <Typography marginBottom={2}>
            As almas vêm à terra em busca de cura.
          </Typography>
          <Typography marginBottom={2}>
            A minha inicia-se já na infância quando passava os verões na praia
            brincando o dia todo, entre a areia e o mar.
          </Typography>
          <Typography marginBottom={2}>
            Nascida em Minas Gerais, sou filha da terra, mas no percorrer da
            vida descobri que de alma sou também feita de areia. Meu primeiro
            encontro com a argila foi fluido e libertador; senti a completude,
            algo que me envolveu por inteiro. Anos depois, ainda carregando esse
            sentimento, concluí minha formação em Artes Plásticas na
            Universidade Estadual de Minas Gerais - Escola de Artes Guignard, no
            ano de 2000. O desejo de compartilhar esse envolvimento mágico com a
            arte me levou ao caminho da pedagogia Waldorf, onde me formei
            professora.
          </Typography>
          <Typography marginBottom={2}>
            No percorrer da vida, após uma longa pausa, retornei à cerâmica
            escultórica no Colorado Mountain College, nos Estados Unidos. Esse
            reencontro me levou a buscar experiências artísticas em diferentes
            lugares do mundo, mas foi em Florianópolis/SC que criei raízes
            profundas e onde meus dois filhos nasceram.
          </Typography>
          <Typography marginBottom={2}>
            Há dez anos, construí meu ateliê e um forno a lenha, o processo de
            queima sempre me fascinou, mesmo antes de conhecê-lo em sua
            multiplicidade. O fogo, com sua dança lenta e ardente, marca as
            peças em um ritual de paciência e entrega. Transformar barro em
            cerâmica é um ato eternizador, um rito de passagem tanto simbólico,
            quanto molecular da terra.
          </Typography>
          <Typography marginBottom={2}>
            Já a prática escultórica nas praias, modelando a areia, me acompanha
            ao longo dos anos. Os momentos de criação, solitários e meditativos,
            são um diálogo com o tempo do fazer. As formas nascem no trabalho de
            um dia inteiro, sendo finalizadas com a subida da maré, quando o
            trabalho se completa com a água do mar invadindo todos os caminhos
            que minhas mãos cavaram. Nesse processo efêmero, no fim do dia a
            escultura se dissolve no mar, em um sentimento de impermanência e
            esperança.
          </Typography>
          <Typography>
            No fazer artístico encontro a crença no amor, na beleza e na
            potência da natureza, fonte inesgotável de inspiração.
          </Typography>
        </Box>

        <Box sx={{ flex: 1, letterSpacing: 1 }}>
          <Typography
            sx={{
              ml: { md: 2 },
              fontStyle: "italic",
              display: { md: "none" },
              my: 5,
            }}
            variant="h5"
          >
            Biography
          </Typography>
          <Typography marginBottom={2}>
            Souls come to Earth in search of healing.
          </Typography>
          <Typography marginBottom={2}>
            Mine began in childhood when I would spend my summers on the beach,
            playing all day long, between the sand and the sea.
          </Typography>
          <Typography marginBottom={2}>
            Born in Minas Gerais, I am a daughter of the earth, but throughout
            life I discovered that my soul is also made of sand. My first
            encounter with clay was fluid and liberating; I felt a sense of
            completeness, something that embraced me entirely. Years later,
            still carrying this feeling, I graduated in Fine Arts from the State
            University of Minas Gerais - Escola de Artes Guignard, in 2000. The
            desire to share this magical involvement with art led me to the path
            of Waldorf pedagogy, where I trained as a teacher.
          </Typography>
          <Typography marginBottom={2}>
            As life unfolded, after a long pause, I returned to sculptural
            ceramics at Colorado Mountain College in the United States. This
            reunion led me to seek artistic experiences in different parts of
            the world, but it was in Florianópolis, Santa Catarina, that I
            planted deep roots and where my two children were born.
          </Typography>
          <Typography marginBottom={2}>
            Ten years ago, I built my studio and a wood-fired kiln. The firing
            process has always fascinated me, even before I understood its full
            complexity. Fire, with its slow and burning dance, marks the pieces
            in a ritual of patience and surrender. Transforming clay into
            ceramics is an act of eternalization, a rite of passage that is both
            symbolic and a molecular transformation of the earth.
          </Typography>
          <Typography marginBottom={2}>
            My sculptural practice on the beaches, modeling the sand, has
            accompanied me throughout the years. The moments of creation,
            solitary and meditative, are a dialogue with the time of making. The
            forms are born from the work of an entire day, being completed with
            the rise of the tide, when the work culminates with the seawater
            invading all the paths my hands have carved. In this ephemeral
            process, at the end of the day, the sculpture melts into the sea,
            evoking a feeling of impermanence and hope.
          </Typography>
          <Typography>
            In artistic creation, I find a belief in love, beauty, and the power
            of nature-an inexhaustible source of inspiration.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
